import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { base64toBlob } from 'app/shared/elements/base64-to-blob';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  CustomEmail,
  CustomTransactionalEmailUpdateDTO,
  EmailSettingsDTO,
  EmailSettingsUpdateDTO,
  EmailVerificationRequest,
  RawEmailBlock,
  TransactionalEmailCategoryDTO,
  TransactionalEmailDTO,
  TransactionalEmailUpdateDTO,
} from './tx-emails.interface';

@Injectable()
export class TxEmailsService {
  constructor(private http: HttpClient) {
  }

  getAvailableBlocks(txEmailId = ''): Observable<Array<RawEmailBlock>> {
    const params = new HttpParams({
      fromObject: {
        txEmailId
      }
    })
    return this.http.get<Array<RawEmailBlock>>(
      `${environment.apiUrl}/api/v1/tx-emails/blocks`, { params }
    );
  }

  getTxEmails(): Observable<Array<TransactionalEmailCategoryDTO>> {
    return this.http.get<Array<TransactionalEmailCategoryDTO>>(
      `${environment.apiUrl}/api/v1/tx-emails`
    );
  }

  getTemplateForTxEmail(txEmailID: string): Observable<TransactionalEmailDTO> {
    return this.http.get<TransactionalEmailDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/template/${txEmailID}`
    );
  }

  updateTransactionalEmail(txEmailId: string, update: CustomTransactionalEmailUpdateDTO): Observable<CustomEmail> {
    return this.http.patch<CustomEmail>(
      `${environment.apiUrl}/api/v1/tx-emails/${txEmailId}/details`, update
    );
  }

  updateTemplateForTxEmail(txEmailId: string, update: TransactionalEmailUpdateDTO): Observable<TransactionalEmailDTO> {
    return this.http.patch<TransactionalEmailDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/template/${txEmailId}`, update
    );
  }

  getSettings(): Observable<EmailSettingsDTO> {
    return this.http.get<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/settings`
    );
  }

  updateSettings(update: EmailSettingsUpdateDTO): Observable<EmailSettingsDTO> {
    return this.http.patch<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/settings`, update
    );
  }

  verifyEmail(verificationRequest: EmailVerificationRequest): Observable<EmailSettingsDTO>  {
    return this.http.put<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/verify`, verificationRequest
    );
  }

  resendVerification(): Observable<void> {
    return this.http.post<void>(
      `${environment.apiUrl}/api/v1/tx-emails/account/resend`, {}
    );
  }

  checkVerificationStatus(): Observable<EmailSettingsDTO> {
    return this.http.post<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/status`, {}
    );
  }

  uploadLogo(logo: string): Observable<EmailSettingsDTO> {
    const formData = new FormData();

    const parts = logo.split(';');
    if (parts.length !== 2) {
      throw Error('El formato base64 de la imagen es inválido');
    }
    const contentType = parts[0].split(':')[1];
    const data = parts[1].split(',')[1];

    formData.append('logo', base64toBlob(data, contentType));

    return this.http.patch<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/settings/logo`,
      formData
    );
  }

  createEmailSettings(settings: EmailSettingsDTO): Observable<EmailSettingsDTO> {
    return this.http.post<EmailSettingsDTO>(
      `${environment.apiUrl}/api/v1/tx-emails/account/settings`, settings
    );
  }
}
