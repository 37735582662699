export const CKEditor4BasicToolbarGroups = [
  { name: 'document', groups: ['mode', 'document', 'doctools'] },
  { name: 'clipboard', groups: ['clipboard', 'undo'] },
  { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
  { name: 'forms', groups: ['forms'] },
  '/',
  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
  { name: 'links', groups: ['links'] },
  { name: 'insert', groups: ['insert'] },
  '/',
  { name: 'styles', groups: ['styles'] },
  { name: 'colors', groups: ['colors'] },
  { name: 'tools', groups: ['tools'] },
  { name: 'others', groups: ['others'] },
  { name: 'about', groups: ['about'] }
];

export const CKEditor4BasicRemoveButtons = 'Source,Save,NewPage,Preview,Print,Templates,Cut,Undo,Find,SelectAll,Scayt,Form,Replace,Redo,Copy,Paste,PasteText,PasteFromWord,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,Subscript,Superscript,CopyFormatting,RemoveFormat,Blockquote,CreateDiv,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Table,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Maximize,About,TextColor,ShowBlocks,BGColor,Styles,Format,Font,FontSize';
