export const CKEditor4SimpleToolbarGroups = [
  { name: 'document', groups: ['mode', 'document', 'doctools'] },
  { name: 'clipboard', groups: ['clipboard', 'undo'] },
  { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
  { name: 'forms', groups: ['forms'] },
  '/',
  { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
  { name: 'colors', groups: ['colors'] },
  { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
  { name: 'links', groups: ['links'] },
  { name: 'insert', groups: ['insert'] },
  '/',
  { name: 'styles', groups: ['styles'] },
  { name: 'tools', groups: ['tools'] },
  { name: 'others', groups: ['others'] },
  { name: 'about', groups: ['about'] }
];

export const CKEditor4SimpleRemoveButtons = 'Source,Save,Templates,NewPage,Preview,Print,Cut,Undo,Redo,Scayt,SelectAll,Find,Replace,Copy,Paste,PasteText,PasteFromWord,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Image,Flash,Smiley,SpecialChar,PageBreak,Iframe,Styles,Format,Font,FontSize,BGColor,Maximize,ShowBlocks,About';
